<template>
    <v-app>
        <slot> <!--CONTENT--> </slot>
    </v-app>
</template>

<script>
export default {
    name: "front-layout",
};
</script>

<style scoped>
</style>
