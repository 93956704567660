<template>
    <front-layout>
        <div>404</div>
    </front-layout>
</template>

<script>
import FrontLayout from "../../layouts/FrontLayout";
export default {
    components: { FrontLayout },
    data: function () {
        return {};
    },
};
</script>

<style scoped>
</style>
